export default {
  nav: {
    home: "첫 페이지",
    sign: "로그인",
    language: "언어 전환",
    notice: "공지사항 목록",
    noticeDetail: "공지사항 세부정보",
    wallet: "지갑",
    rule: "게임 규칙",
    withdraw: "현금 인출",
    fundsflow: "회계변경기록",
  },
  tabbar: {
    home: "첫 페이지",
    recharge: "충전하다",
    contact: "고객 서비스",
    account: "내 거",
  },
  language: {
    cn: "중국어 간체",
    tcn: "중국어 번체",
    en: "영어",
    jp: "일본어",
    ko: "한국인",
    da: "힌디 어",
    po: "포르투갈 인",
    es: "스페인 사람",
  },
  button: {
    language: "좋아요",
    recharge: "재충전 제출",
    betting: "도박",
    betting1: "반품",
    withdraw: "철회하다",
    not1: "최저",
    not2: "제일 높은",
    account: "제출하다",
    login: "로그인",
    register: "등록하다",
  },
  account: {
    profit: "오늘의 이익",
    win: "오늘의 승리",
    balance: "균형",
    recharge: "충전하다",
    withdraw: "현금 인출",
    wallet: "지갑",
    notice: "UPC 점수 평가",
    funds: "내 자금 흐름",
    pass: "패스 수정",
    password: "펀드 비밀번호 변경",
    exit: "안전한 출구",
    arecord: "계정 변경 기록",
    rrecord: "충전하다 기록",
    wrecord: "현금 인출 기록",
  },
  home: {
    trade: "거래소",
    play: "게임플레이",
  },
  rule: {
    title: "게임플레이",
    p1: "0~13은 작음, 14~27은 큼",
    p2: "싱글과 더블:",
    p3: "그려진 숫자는 짝수입니다(예: 0, 2, 4, 6, 8, 10).",
    p4: "발행된 숫자는 홀수입니다(예: 1,3,5,7,9,11).",
    p5: "빅 싱글 빅 더블:",
    p6: "예를 들어 ≥ 14라는 숫자는 크고 짝수입니다. 14(큰 짝수) 15(큰 홀수)",
    p7: "소형 싱글 및 소형 더블:",
    p8: "13 이하의 숫자는 작고 홀수입니다. 예: 13(작은 홀수) 12(작은 더블)",
    p9: "소형 싱글 및 소형 더블:",
    p10: "13 이하의 숫자는 작고 홀수입니다. 예: 13(작은 홀수) 12(작은 더블)",
    p11: "게임 규칙:",
    p12: "1. 최고점수는 현금인출 전 원금회전율의 2배가 됩니다.",
    p13: "2. 단일 및 이중 쌍의 크기를 일치시키기 위해 동일한 수 또는 기타 방법을 사용하는 것은 금지되어 있습니다.",
    p14: "3. 악의적인 부정행위로 상금을 획득하는 행위는 엄격히 금지됩니다.",
    p15: "4. 업그레이드된 회원은 현금을 인출하기 전에 해당 매출을 완료해야 합니다.",
    p16: "[참고: 최종 해석권은 본 플랫폼에 있습니다~]",
  },
  indoor: {
    size: "크기",
    number: "숫자",
    special: "특별한",
    until: "~까지",
    period: "기간",
    user: "사용자",
    number: "숫자",
    categroy: "범주",
    transaction: "거래",
    operation: "작동하다",
    balance: "균형",
    periods: "ISSUE",
    total: "총",
    result: "결과",
    text: "플레이 방법을 선택하고 배팅할 금액을 클릭하세요.",
  },
  withdraw: {
    cash: "출금금액",
    all: "모두 철회",
  },
  register: {
    title: "Set up your account",
    passTitle: "Enter password",
    username: "Please enter 6-20 letters, numbers or combinations",
    step: "Next step",
    register: "Member registration",
  },
};
