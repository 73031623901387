export default {
  nav: {
    home: "Hogar",
    sign: "Acceso",
    language: "Cambiar idioma",
    notice: "Lista de anuncios",
    noticeDetail: "Detalle del anuncio",
    wallet: "Billetera",
    rule: "reglas del juego",
    withdraw: "Retirar",
    fundsflow: "Registros de cambios contables",
  },
  tabbar: {
    home: "Hogar",
    recharge: "Recargar",
    contact: "Contacto",
    account: "Cuenta",
  },
  language: {
    cn: "Chino simplificado",
    tcn: "chino tradicional",
    en: "idioma en Inglés",
    jp: "japonés",
    ko: "coreano",
    da: "hindi",
    po: "Português",
    es: "Español",
  },
  button: {
    language: "DE ACUERDO",
    recharge: "Enviar recarga",
    betting: "Apuesta",
    betting1: "Devolver",
    withdraw: "Retirar",
    not1: "Más bajo",
    not2: "más alto",
    account: "Entregar",
    login: "Acceso",
    register: "Registro",
  },
  account: {
    profit: "Beneficio de hoy",
    win: "La victoria de hoy",
    balance: "Balance",
    recharge: "Recargar",
    withdraw: "Retirar",
    wallet: "Billetera",
    notice: "Evaluación de puntuación UPC",
    funds: "mis fondos fluyen",
    pass: "Modificar pase",
    password: "Cambiar contraseña del fondo",
    exit: "salida segura",
    arecord: "Registro de cambio de cuenta",
    rrecord: "Registro de recarga",
    wrecord: "Retirar registro",
  },
  home: {
    trade: "piso de negociación",
    play: "Jugabilidad",
  },
  rule: {
    title: "Jugabilidad",
    p1: "0-13 es pequeño, 14-27 es grande",
    p2: "Sencillo y doble:",
    p3: "Los números extraídos son números pares, por ejemplo: 0, 2, 4, 6, 8, 10.",
    p4: "Los números emitidos son impares, por ejemplo: 1,3,5,7,9,11",
    p5: "Grande solo grande doble:",
    p6: "El número ≥ 14 es grande y par, por ejemplo; 14 (par grande) 15 (impar grande)",
    p7: "Pequeña individual y pequeña doble:",
    p8: "El número ≤ 13 es pequeño e impar, por ejemplo: 13 (pequeño impar) 12 (pequeño doble)",
    p9: "Pequeña individual y pequeña doble:",
    p10: "El número ≤ 13 es pequeño e impar, por ejemplo: 13 (pequeño impar) 12 (pequeño doble)",
    p11: "Reglas del juego:",
    p12: "1. La puntuación máxima es el doble de la facturación del principal antes de que se pueda realizar el retiro de efectivo.",
    p13: "2. Está prohibido utilizar el mismo número u otros métodos para igualar el tamaño de pares simples y dobles.",
    p14: "3. Está estrictamente prohibido ganar premios en metálico de forma fraudulenta y maliciosa.",
    p15: "4. Los miembros ascendidos deben completar la facturación correspondiente antes de poder retirar efectivo.",
    p16: "[Nota: el derecho de interpretación final pertenece a esta plataforma ~]",
  },
  indoor: {
    size: "Tamaño",
    number: "Número",
    special: "Especial",
    until: "hasta",
    period: "período",
    user: "usuario",
    number: "número",
    categroy: "categoría",
    transaction: "comercio",
    operation: "operación",
    balance: "balance",
    periods: "ISSUE",
    total: "total",
    result: "resultado",
    text: "Selecciona el método de juego y haz clic en la cantidad a apostar.",
  },
  withdraw: {
    cash: "Retirar cantidad",
    all: "Retirar todo",
  },
  register: {
    title: "Configura tu cuenta",
    passTitle: "Introduzca la contraseña",
    username: "Introduzca entre 6 y 20 letras, números o combinaciones",
    step: "Siguiente paso",
    register: "Registro de miembros",
  },
};
