// axios.js

import axios from "axios";
import global from "./config.js/global";
import { Toast } from "vant";

const request = axios.create({
  // baseURL: 'http://localhost:9327',
  baseURL: "/app",
  timeout: 50000,
});

request.interceptors.request.use(
  (config) => {
    config.headers[global.tokenName] = localStorage.getItem(global.tokenName);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => {
    let res = response.data;
    if (res.code == 200 || res.code == 20010 || res.code == 20011) {
      return response.data;
    } else if (res.code == 401) {
      if (!localStorage.getItem(global.tokenName)) {
        Toast.fail("account or password error");
      }
      location.reload();
    } else if(res.code == 500) {
      Toast.fail(res.msg);
      return res;
    }
    return Promise.reject(new Error(""));
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default request;
