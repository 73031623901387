export default {
  nav: {
    home: "Home",
    sign: "Log In",
    language: "Swith language",
    notice: "Announcement List",
    noticeDetail: "Announcement Detail",
    wallet: "Wallet",
    rule: "game rules",
    withdraw: "Withdraw",
    fundsflow: "Accounting change records",
  },
  tabbar: {
    home: "Home",
    recharge: "Recharge",
    contact: "Contact",
    account: "Account",
  },
  language: {
    cn: "Simplified Chinese",
    tcn: "Traditional Chinese",
    en: "Enlish language",
    jp: "Japanese",
    ko: "Korean",
    da: "hindi",
    po: "Portuguese",
    es: "Spanish",
  },
  button: {
    language: "OK",
    recharge: "Submit recharge",
    betting: "Betting",
    betting1: "Return",
    withdraw: "Withdraw",
    not1: "Lowest",
    not2: "Highest",
    account: "Submit",
    login: "Login",
    register: "Register",
  },
  account: {
    profit: "Today Profit",
    win: "Today's Win",
    balance: "Balance",
    recharge: "Recharge",
    withdraw: "Withdraw",
    wallet: "Wallet",
    notice: "UPC score evaluation",
    funds: "My funds flow",
    pass: "Modify Pass",
    password: "Change fund password",
    exit: "Safe exit",
    arecord: "Account change record",
    rrecord: "Recharge record",
    wrecord: "Withdraw record",
  },
  home: {
    trade: "trading floor",
    play: "Gameplay",
  },
  rule: {
    title: "Gameplay",
    p1: "0-13 is small, 14-27 is big",
    p2: "Single and double:",
    p3: "The numbers drawn out are even numbers, for example: 0, 2, 4, 6, 8, 10",
    p4: "The numbers issued are odd numbers, for example: 1,3,5,7,9,11",
    p5: "Big single big double:",
    p6: "The number ≥ 14 is big and even, for example; 14 (big even) 15 (big odd)",
    p7: "Small single and small double:",
    p8: "The number ≤ 13 is small and odd, for example: 13 (small odd) 12 (small double)",
    p9: "Small single and small double:",
    p10: "The number ≤ 13 is small and odd, for example: 13 (small odd) 12 (small double)",
    p11: "Game rules:",
    p12: "1. The top score is double the turnover of the principal before cash withdrawal can be made.",
    p13: "2. It is forbidden to use the same number or other methods to match the size of single and double pairs.",
    p14: "3. Malicious fraudulent winning of prize money is strictly prohibited.",
    p15: "4. Upgraded members need to complete the corresponding turnover before they can withdraw cash.",
    p16: "[Note: The final interpretation right belongs to this platform~]",
  },
  indoor: {
    size: "Size",
    number: "Number",
    special: "Special",
    until: "until",
    period: "period",
    user: "user",
    number: "number",
    categroy: "categroy",
    transaction: "trade",
    operation: "operation",
    balance: "balance",
    periods: "ISSUE",
    total: "total",
    result: "result",
    text: "Select the method of play and click the amount to bet",
  },
  withdraw: {
    cash: "Withdraw amount",
    all: "Withdraw all",
  },
  register: {
    title: "Set up your account",
    passTitle: "Enter password",
    username: "Please enter 6-20 letters, numbers or combinations",
    step: "Next step",
    register: "Member registration",
  },
};
