export default {
  nav: {
    home: "Casa",
    sign: "Conecte-se",
    language: "Mudar idioma",
    notice: "Lista de anúncios",
    noticeDetail: "Detalhe do anúncio",
    wallet: "Carteira",
    rule: "regras do jogo",
    withdraw: "Retirar",
    fundsflow: "Registos de alterações contábeis",
  },
  tabbar: {
    home: "Casa",
    recharge: "Recarregar",
    contact: "Contacto",
    account: "Conta",
  },
  language: {
    cn: "Chinês simplificado",
    tcn: "Chinês Tradicional",
    en: "língua Inglesa",
    jp: "japonês",
    ko: "coreano",
    da: "hindi",
    po: "Português",
    es: "Espanhol",
  },
  button: {
    language: "OK",
    recharge: "Enviar recarga",
    betting: "Apostas",
    betting1: "Devolver",
    withdraw: "Retirar",
    not1: "Mais baixo",
    not2: "Mais alto",
    account: "Submeter",
    login: "Conecte-se",
    register: "Registar",
  },
  account: {
    profit: "Lucro de hoje",
    win: "Vitória de hoje",
    balance: "Equilíbrio",
    recharge: "Recarregar",
    withdraw: "Retirar",
    wallet: "Carteira",
    notice: "Avaliação da pontuação UPC",
    funds: "O meu fluxo de fundos",
    pass: "Modificar passe",
    password: "Alterar a palavra-passe do fundo",
    exit: "Saída segura",
    arecord: "Registo de alteração de conta",
    rrecord: "Registo de recarga",
    wrecord: "Registo de retirada",
  },
  home: {
    trade: "pregão",
    play: "Jogabilidade",
  },
  rule: {
    title: "Jogabilidade",
    p1: "0-13 é pequeno, 14-27 é grande",
    p2: "Simples e duplo:",
    p3: "Os números sorteados são números pares, por exemplo: 0, 2, 4, 6, 8, 10",
    p4: "Os números emitidos são números ímpares, por exemplo: 1,3,5,7,9,11",
    p5: "Grande único grande duplo:",
    p6: "O número ≥ 14 é grande e par, por exemplo; 14 (grande par) 15 (grande ímpar)",
    p7: "Pequeno individual e pequeno duplo:",
    p8: "O número ≤ 13 é pequeno e ímpar, por exemplo: 13 (pequeno ímpar) 12 (pequeno duplo)",
    p9: "Pequeno individual e pequeno duplo:",
    p10: "O número ≤ 13 é pequeno e ímpar, por exemplo: 13 (pequeno ímpar) 12 (pequeno duplo)",
    p11: "Regras do jogo:",
    p12: "1. A pontuação máxima é o dobro do giro do capital antes de poder ser feito o levantamento em dinheiro.",
    p13: "2.º É proibido utilizar o mesmo número ou outros métodos para combinar o tamanho de pares simples e duplos.",
    p14: "3.º É estritamente proibido ganhar prémios em dinheiro de forma fraudulenta e maliciosa.",
    p15: "4.º Os membros atualizados precisam de completar o giro correspondente antes de poderem levantar dinheiro.",
    p16: "[Nota: O direito de interpretação final pertence a esta plataforma~]",
  },
  indoor: {
    size: "Tamanho",
    number: "Número",
    special: "Especial",
    until: "até",
    period: "período",
    user: "utilizador",
    number: "número",
    categroy: "categoria",
    transaction: "comércio",
    operation: "operação",
    balance: "equilíbrio",
    periods: "ISSUE",
    total: "total",
    result: "resultado",
    text: "Selecione o método de jogo e clique no valor a apostar",
  },
  withdraw: {
    cash: "Retirar valor",
    all: "Retirar tudo",
  },
  register: {
    title: "Configure a sua conta",
    passTitle: "Introduza a senha",
    username: "Introduza 6 a 20 letras, números ou combinações",
    step: "Próxima etapa",
    register: "Registo de membro",
  },
};
