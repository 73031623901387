<template>
  <div class="home">
    <div class="nav-background">
      <van-nav-bar fixed placeholder :title="$t('nav.home')">
        <template #left>
          <div @click="navTo('/language/1')">{{ $t("nav.language") }}</div>
        </template>
        <template #right>
          <div v-show="showLogin" @click="navTo('/login')">
            {{ $t("nav.sign") }}
          </div>
        </template>
      </van-nav-bar>
    </div>

    <div style="margin: 0 10px 10px 10px; height: 179px">
      <van-swipe style="border-radius: 6%" :autoplay="3000">
        <!-- <van-swipe-item v-for="(image, index) in images" :key="index">
          <img v-lazy="image" />
           {{ index }}
        </van-swipe-item> -->
        <!-- <van-swipe-item>
          <img
            style="width: 360px; height: 179px"
            src="@/assets/banner01.jpg"
          />
        </van-swipe-item>
        <van-swipe-item>
          <img
            style="width: 360px; height: 179px"
            src="@/assets/banner02.jpg"
          />
        </van-swipe-item>
        <van-swipe-item>
          <img
            style="width: 360px; height: 179px"
            src="@/assets/banner03.jpg"
          />
        </van-swipe-item>
        <van-swipe-item>
          <img
            style="width: 360px; height: 179px"
            src="@/assets/banner04.jpg"
          />
        </van-swipe-item> -->
        <van-swipe-item>
          <img
            style="width: 100%; height: 179px; object-fit: fill"
            src="@/assets/banner01.jpg"
          />
        </van-swipe-item>
        <van-swipe-item>
          <img
            style="width: 100%; height: 179px; object-fit: fill"
            src="@/assets/banner02.jpg"
          />
        </van-swipe-item>
        <van-swipe-item>
          <img
            style="width: 100%; height: 179px; object-fit: fill"
            src="@/assets/banner03.jpg"
          />
        </van-swipe-item>
        <van-swipe-item>
          <img
            style="width: 100%; height: 179px; object-fit: fill"
            src="@/assets/banner04.jpg"
          />
        </van-swipe-item>
      </van-swipe>
    </div>

    <div>
      <van-notice-bar
        @click="navTo('/announcement/1')"
        left-icon="volume-o"
        :scrollable="false"
      >
        <van-swipe
          vertical
          class="notice-swipe"
          :autoplay="3000"
          :show-indicators="false"
        >
          <van-swipe-item v-for="(item, index) in noticeTitle" :key="index">{{
            item.noticeTitle
          }}</van-swipe-item>
          <!-- <van-swipe-item>Platform announcement Precautions for stored value</van-swipe-item> -->
        </van-swipe>
        <template #right-icon>
          <van-icon name="more" size="20" />
        </template>
      </van-notice-bar>
    </div>

    <div>
      <van-cell
        title-style="text-align: left"
        :title="$t('home.trade')"
        value=""
        :border="false"
      />
    </div>

    <!-- <div style="margin: 0 10px 10px 10px">
      <van-image
        radius="10px"
        width="100%"
        height="100"
        @click=""
        src="https://img01.yzcdn.cn/vant/cat.jpeg"
      />
    </div> -->

    <div style="margin: 0 10px 10px 10px" class="img-but">
      <van-image
        radius="10px"
        width="100%"
        height="210"
        @click="navTo('/room/1')"
        :src="require('@/assets/vip1.jpg')"
      />
    </div>

    <div style="margin: 0 10px 10px 10px" class="img-but">
      <van-image
        radius="10px"
        width="100%"
        height="210"
        @click="navTo('/room/2')"
        :src="require('@/assets/vip2.jpg')"
      />
    </div>

    <div style="margin: 0 10px 10px 10px" class="img-but">
      <van-image
        radius="10px"
        width="100%"
        height="210"
        @click="navTo('/room/3')"
        :src="require('@/assets/vip3.jpg')"
      />
    </div>

    <div style="margin: 0 10px 10px 10px" class="img-but">
      <van-image
        radius="10px"
        width="100%"
        height="210"
        @click="navTo('/room/4')"
        :src="require('@/assets/vip4.jpg')"
      />
    </div>

    <!-- <div style="margin: 0 10px 10px 10px" class="img-but">
      <van-image
        radius="10px"
        width="100%"
        height="150"
        @click="navTo('/room/1')"
        src="https://img01.yzcdn.cn/vant/cat.jpeg"
      />
    </div> -->

    <!-- <div style="margin: 0 10px 10px 10px" class="img-but">
      <van-image
        radius="10px"
        width="100%"
        height="150"
        @click=""
        src="https://img01.yzcdn.cn/vant/cat.jpeg"
      />
      <div class="but"><van-button round plain text="GO >"></van-button></div>
    </div>

    <div style="margin: 0 10px 10px 10px" class="img-but">
      <van-image
        radius="10px"
        width="100%"
        height="150"
        @click=""
        src="https://img01.yzcdn.cn/vant/cat.jpeg"
      />
      <div class="but"><van-button round plain text="GO >"></van-button></div>
    </div> -->

    <div>
      <van-tabbar
        safe-area-inset-bottom
        placeholder
        v-model="active"
        @change="onChange"
      >
        <van-tabbar-item to="/" icon="home-o" name="0">{{
          $t("tabbar.home")
        }}</van-tabbar-item>
        <van-tabbar-item
          :to="showLogin ? '/login' : '/recharge'"
          icon="cash-o"
          name="1"
          >{{ $t("tabbar.recharge") }}</van-tabbar-item
        >
        <van-tabbar-item :url="config.telegraph" icon="service-o" name="2">{{
          $t("tabbar.contact")
        }}</van-tabbar-item>
        <van-tabbar-item
          :to="showLogin ? '/login' : '/account'"
          icon="manager-o"
          name="3"
          >{{ $t("tabbar.account") }}</van-tabbar-item
        >
      </van-tabbar>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { getNoticeTitle } from "@/common/api/api";

export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      images: ["@/assets/banner01.png", "@/assets/banner02.png"],
      active: 0,
      showLogin: true,
      config: {},
      url: "",
      config: {},
      noticeTitle: [],
    };
  },
  created() {
    const token = localStorage.getItem(this.global.tokenName);
    if (token != null && token != "") this.showLogin = false;
    this.config = this.$store.getters.config;
    // this.config.telegraph = ""
    this.getNotice();
  },
  methods: {
    navTo(path) {
      this.$router.push(path);
    },
    onChange(val) {
      this.active = val;
    },
    getNotice() {
      getNoticeTitle().then((res) => {
        this.noticeTitle = res.data;
      });
    },
  },
};
</script>

<style lang="scss">
.nav-background {
  .van-nav-bar {
    background: linear-gradient(to right, #9d8040, #9d8040);
  }
}

.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #39a9ed;
}

.notice-swipe {
  height: 40px;
  line-height: 40px;
}

.img-but {
  position: relative;
}

.but {
  position: absolute;
  right: 20px;
  bottom: 30px;
}
</style>
