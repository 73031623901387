import { getInfo, logout, getConfig } from "@/common/api/api";
import global from "@/common/config.js/global";

const state = {
  userInfo: {
    userId: "",
    username: "",
    money: "",
    account: "",
    showMoney: "",
    grade: "",
    nextGrade: "",
    diff: "",
    withdrawNum: 0,
    percentage: "",
    quota: "",
    showDiff: "",
  },
  sizeOrder: {},
  config: {
    configId: "",
    lowestRechargeCash: "",
    lowestWithdrawCash: "",
    maxWithdrawCash: "",
    withdrawTime: "",
    telegraph: "",
    poundage: "",
    rechargeFailurePrompt: "",
    currencySymbol: "",
  },
};

const mutations = {
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo;
  },
  RESET_STATE: (state) => {
    state.userInfo = {};
  },
  SET_SIZE: (state, sizeOrder) => {
    state.sizeOrder = sizeOrder;
  },
  SET_CONFIG: (state, config) => {
    state.config = config;
  },
};

const actions = {
  getInfo({ commit }) {
    return new Promise((resolve, reject) => {
      getInfo()
        .then((response) => {
          const { data } = response;

          if (!data) {
            return reject("Verification failed, please Login again.");
          }

          const userInfo = {
            userId: data.userId,
            username: data.username,
            money: data.money,
            account: data.account,
            showMoney: data.showMoney,
            grade: data.grade,
            nextGrade: data.nextGrade,
            diff: data.diff,
            withdrawNum: data.withdrawNum,
            percentage: data.percentage,
            quota: data.quota,
            showDiff: data.showDiff
          };

          commit("SET_USERINFO", userInfo);
          // localStorage.setItem("userInfo", userInfo);

          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  logout({ commit }) {
    return new Promise((resolve, reject) => {
      logout()
        .then(() => {
          localStorage.removeItem(global.tokenName);
          // localStorage.removeItem("userInfo");
          commit("RESET_STATE");
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  // sizeOrder({ commit, sizeOrder }) {
  //   return () => {
  //     commit("SET_SIZE", sizeOrder);
  //   }
  // },

  getConfig({ commit }) {
    return new Promise((resolve, reject) => {
      getConfig()
        .then((res) => {
          const { data } = res;
          // console.log("data",data)
          if (!data) {
            return reject("Verification failed, please Login again.");
          }
          const config = {
            configId: data.configId,
            lowestRechargeCash: data.lowestRechargeCash,
            lowestWithdrawCash: data.lowestWithdrawCash,
            maxWithdrawCash: data.maxWithdrawCash,
            withdrawTime: data.withdrawTime,
            telegraph: data.telegraph,
            poundage: data.poundage,
            rechargeFailurePrompt: data.rechargeFailurePrompt,
            currencySymbol: data.currencySymbol,
          };
          commit("SET_CONFIG", config);
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  state,
  mutations,
  actions,
};
